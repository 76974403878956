import React from 'react';
import ReactPlayer from 'react-player';
import Vimeo from '@u-wave/react-vimeo';
import { graphql, Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import ReactMarkdown from 'react-markdown';
import Layout from '../components/layout';
import Quote from '../components/quote';
import { getFallbackFromImageData } from '../utils';

import './artwork.scss';
import SEO from '../components/seo';
import UbuntuVideo from '../components/ubuntu-video';
import Landless from '../components/landless';

const DECORATIONS = [1, 3, 8, 11, 13, 14];

export default function BlogPost({ data, location } : any) {
  const {
    title, description, portraitImage, landscapeImage, imageFileWidth,
    imageFileHeight, author: {
      name: authorName, bio: authorBio, twitter, instagram, website,
    }, slug,
    video, quote, format, yearOfCreation, imageFile2, imageFile3, imageFile4,
    imageFile5, imageFile6, imageFile7, imageFile8, imageFile9, imageFile10,
  } = data.django.artworkBySlug;

  const { nextArtwork } = data.django;
  const isPortrait = (imageFileWidth < imageFileHeight && !video) || slug === 'untitled';
  const image = getImage(isPortrait ? portraitImage : landscapeImage);

  const decorationNumber = DECORATIONS[title.length % DECORATIONS.length];

  const isVideoLooped = ['a-sacred-future', 'untitled'].includes(slug);

  let videoContent = <></>;

  if (video || slug === 'climate-despair' || slug === 'landless') {
    if (slug === 'climate-despair') {
      videoContent = <UbuntuVideo />;
    } else if (slug === 'landless') {
      videoContent = <Landless />;
    } else if (video) {
      videoContent = (
        <div className="artwork-page-video">
          {video.includes('vimeo')
            ? (
              <Vimeo
                video={video}
                showTitle={false}
                showPortrait={false}
                showByline={false}
                width="100%"
                height="100%"
                loop={isVideoLooped}
              />
            )
            : <ReactPlayer src={video} width="100%" height="100%" />}
        </div>
      );
    }
  }

  return (
    <Layout homeTarget="/gallery" homeLabel="Gallery" homeState={location.state}>
      <SEO
        title={title}
        metaTitle={`${title} - The Colour of the Climate Crisis`}
        image={`https://thecolouroftheclimatecrisis.art${
          getFallbackFromImageData(landscapeImage)}`}
      />
      <div className="artwork-page">
        <div className={`artwork-page-main${isPortrait ? ' is-portrait' : ''}`}>
          {video || slug === 'climate-despair' || slug === 'landless' ? (
            <div className="artwork-page-video-container">
              {videoContent}
            </div>
          ) : (
            <div className="artwork-page-image-container">
              <div className="artwork-page-image">
                <GatsbyImage
                  image={image}
                  alt={title}
                  aria-describedby={`${slug}-desc`}
                />
              </div>
            </div>
          )}
          <div className="artwork-page-main-description">
            <p>{`“${title}” by ${authorName}`}</p>
            <p>{yearOfCreation}</p>
            { format ? <p>{format}</p> : ''}
          </div>
        </div>
        {
          [imageFile2, imageFile3, imageFile4, imageFile5, imageFile6, imageFile7,
            imageFile8, imageFile9, imageFile10].map((imageFile, i) => {
            if (imageFile) {
              return (
                // eslint-disable-next-line
                <div className="artwork-page-secondary-image" key={i}>
                  <div className="artwork-page-image">
                    <GatsbyImage
                      image={getImage(imageFile)}
                      alt=""
                    />
                  </div>
                </div>
              );
            }
            return '';
          })
        }
        <div className="artwork-page-details">
          <div>
            {quote
              ? (
                <div className="artwork-page-quote">
                  <Quote>
                    {quote}
                  </Quote>
                </div>
              ) : ''}
            <div className="artwork-page-description">
              <div id={`${slug}-desc`}>
                <h3 className="artwork-page-description-headline">
                  About the artwork
                </h3>
                {/* eslint-disable-next-line */}
                <ReactMarkdown children={description} skipHtml />
              </div>
              <div className="artwork-page-description-bio">
                <h3 className="artwork-page-description-headline">
                  About the artist
                </h3>
                {/* eslint-disable-next-line */}
                <ReactMarkdown children={authorBio} skipHtml />
                { twitter || instagram || website ? (
                  <ul className="artwork-page-social-links">
                    { twitter ? (
                      <li>
                        <a href={twitter} target="_blank" rel="noreferrer">Twitter</a>
                      </li>
                    ) : '' }
                    {instagram ? (
                      <li>
                        <a href={instagram} target="_blank" rel="noreferrer">Instagram</a>
                      </li>
                    ) : ''}
                    {website ? (
                      <li>
                        <a href={website} target="_blank" rel="noreferrer">Website</a>
                      </li>
                    ) : ''}
                  </ul>
                ) : '' }
              </div>
              {nextArtwork ? (
                <Link to={`/artwork/${nextArtwork.slug}`} className="artwork-page-next-piece">
                  <div className="artwork-page-next-piece-cta">
                    Next
                  </div>
                  <div className="artwork-page-next-piece-image">
                    <GatsbyImage
                      image={getImage(nextArtwork.imageFile)}
                      alt={nextArtwork.title}
                    />
                  </div>
                </Link>
              ) : ''}
            </div>
          </div>
          <div className="artwork-page-gutter">
            <img src={`/cutouts/${decorationNumber}.png`} alt="" />
          </div>
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  query($slug: String!, $next: String!) {
    django {
      artworkBySlug(slug: $slug) {
        slug
        title
        description
        author {
          name
          bio
          instagram
          twitter
          website
        }
        image
        image2
        image3
        image4
        image5
        image6
        image7
        image8
        image9
        image10
        video
        audio
        quote
        imageFileWidth
        imageFileHeight
        format
        yearOfCreation
        portraitImage: imageFile {
          childImageSharp {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              quality: 95
            )
          }
        }
        landscapeImage: imageFile {
          childImageSharp {
            gatsbyImageData(
              width: 1200
              placeholder: BLURRED
              quality: 95
            )
          }
        }
        imageFile2 {
          childImageSharp {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              quality: 95
            )
          }
        }
        imageFile3 {
          childImageSharp {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              quality: 95
            )
          }
        }
        imageFile4 {
          childImageSharp {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              quality: 95
            )
          }
        }
        imageFile5 {
          childImageSharp {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              quality: 95
            )
          }
        }
        imageFile6 {
          childImageSharp {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              quality: 95
            )
          }
        }
        imageFile7 {
          childImageSharp {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              quality: 95
            )
          }
        }
        imageFile8 {
          childImageSharp {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              quality: 95
            )
          }
        }
        imageFile9 {
          childImageSharp {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              quality: 95
            )
          }
        }
        imageFile10 {
          childImageSharp {
            gatsbyImageData(
              width: 600
              placeholder: BLURRED
              quality: 95
            )
          }
        }
      }
      nextArtwork: artworkBySlug(slug: $next) {
        slug
        title
        image
        imageFile {
          childImageSharp {
            gatsbyImageData(
              width: 460
              placeholder: BLURRED
              quality: 95
            )
          }
        }
      }
    }
  }
`;
