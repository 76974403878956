import React from 'react';

interface BaseImageData {
  srcSet: string
  sizes: string
}

interface FallbackImageData extends BaseImageData {
  src: string
}

interface SourceImageData extends BaseImageData {
  type: string
}

interface ImageData {
  images: {
    fallback: FallbackImageData
    sources: SourceImageData[]
  }
}

interface ImageEntry {
  slug: string
  imageSrc: string
}

export const buildImageFromData = (
  { images: { fallback, sources: [image] } }: ImageData,
  alt = '',
) => (
  <picture>
    <source
      srcSet={image.srcSet}
      sizes={image.sizes}
      type={image.type}
    />
    <img
      src={fallback.src}
      srcSet={fallback.srcSet}
      sizes={fallback.sizes}
      alt={alt}
    />
  </picture>
);

export const waitFor = (milliseconds: number) => new Promise(
  (resolve) => setTimeout(resolve, milliseconds),
);

export const easeInOutSine = (x: number) => -(Math.cos(Math.PI * x) - 1) / 2;

export const getFallbackFromImageData = (
  { childImageSharp }: any,
) => childImageSharp.gatsbyImageData.images.fallback.src;

export const getImagesFromData = (imageData: any)
: ImageEntry[] => imageData.django.allArtworks.map(
  ({ slug, imageFile }: any) => ({
    slug,
    imageSrc: imageFile.childImageSharp.gatsbyImageData.images.fallback.src,
  }),
);

export default {
  buildImageFromData, waitFor, easeInOutSine, getFallbackFromImageData, getImagesFromData,
};
