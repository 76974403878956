import React from 'react';

const title = 'Rebekah Ubuntu | Despair, Hope and Healing: Three Movements for Climate Justice';

const UbuntuVideo = () => (
  <div itemScope itemType="https://schema.org/VideoObject">
    <meta
      itemProp="uploadDate"
      content="Mon Oct 18 2021 16:32:00 GMT+0100 (British Summer Time)"
    />
    <meta
      itemProp="name"
      content={title}
    />
    <meta
      itemProp="description"
      content={title}
    />
    <meta
      itemProp="duration"
      content="P0Y0M0DT0H22M6S"
    />
    <meta
      itemProp="thumbnailUrl"
      content="https://content.jwplatform.com/thumbs/PwJhzDDh-1920.jpg"
    />
    <meta
      itemProp="contentUrl"
      content="https://content.jwplatform.com/videos/PwJhzDDh-EMbiUAtK.mp4"
    />
    <div
      style={{
        position: 'relative',
        overflow: 'hidden',
        paddingBottom: '56.25%',
      }}
    >
      <iframe
        src="https://cdn.jwplayer.com/players/PwJhzDDh-2WZdMdG5.html"
        width="100%"
        height="100%"
        frameBorder="0"
        scrolling="auto"
        title={title}
        style={{ position: 'absolute' }}
        allowFullScreen
      />

    </div>
  </div>
);

export default UbuntuVideo;
