import React from 'react';

import './landless.scss';

const Landless = () => (
  <div className="landless">
    <iframe
      src="https://www.landless.online/"
      frameBorder={0}
      title="Landless"
    />
  </div>
);

export default Landless;
