import React from 'react';

import './quote.scss';

interface QuoteProps {
  children: React.ReactNode
  author?: string
}

const Quote = ({ children, author } : QuoteProps) => (
  <figure className="quote-block">
    <blockquote className="quote-block-text">
      {children}
    </blockquote>
    { author ? (
      <figcaption className="quote-block-author">
        &mdash;&nbsp;
        {author}
      </figcaption>
    ) : ''}
  </figure>
);

Quote.defaultProps = {
  author: null,
};

export default Quote;
